import React from 'react';
import {
  Switch, BrowserRouter as Router, Route, Redirect,
} from 'react-router-dom';

import { ADMIN_ADDRESS, LOCATION } from 'constants/index';
import useWeb3 from 'hooks/useWeb3';

import HomePage from 'pages/HomePage/HomePage';
import AboutPage from 'pages/AboutPage/AboutPage';
// import StoriesPage from 'pages/StoriesPage/StoriesPage';
import ESGPage from 'pages/ESGPage/ESGPage';
import AllNFTs from 'pages/AllNFTs/AllNFTs';
import ScrollToTop from 'components/ScrollToTop';
import CreatePage from 'pages/Create';
import AddCollection from 'pages/AddCollection';
import MyCollection from 'pages/MyCollection/MyCollection';
import AdminPage from 'pages/AdminPage/AdminPage';
import UserPage from 'pages/UserPage/UserPage';
import LoginPage from 'pages/LoginPage/LoginPage';
import Layout from 'Layout/Layout';

import './App.scss';

function App() {
  const { wallet } = useWeb3();

  return (
    <Router>
      <ScrollToTop />
      <Switch>
        <Route path={LOCATION.LOGIN} component={LoginPage} />
        <Layout>
          <Route path={LOCATION.HOME_PAGE} component={HomePage} exact />
          <Route path={LOCATION.ABOUT_PAGE} component={AboutPage} exact />
          {/* <Route path={LOCATION.STORIES_PAGE} component={StoriesPage} exact /> */}
          <Route path={LOCATION.ESG_PAGE} component={ESGPage} exact />
          <Route path={LOCATION.ALL_NFT} component={AllNFTs} />
          {wallet.address === ADMIN_ADDRESS
            ? <Route path={LOCATION.CREATE_PAGE} component={CreatePage} />
            : <Redirect from={LOCATION.CREATE_PAGE} to={LOCATION.HOME_PAGE} />}

          <Route path={LOCATION.ADD_COLLECTION} component={AddCollection} />
          <Route path={LOCATION.My_COLLECTION} component={MyCollection} />

          <Route path={LOCATION.ADMIN_PAGE} component={AdminPage} />
          <Route path={LOCATION.USER_PAGE} component={UserPage} />
        </Layout>
      </Switch>
    </Router>
  );
}

export default App;
