/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select';
import * as Yup from 'yup';
import { useFormik } from 'formik';

import useWeb3 from 'hooks/useWeb3';
import useAlchemy from 'hooks/useAlchemy';

import smile from 'assets/icons/smile.png';

import './AddCollection.scss';
import { toast } from 'react-toastify';

function AddCollection(props) {
  const {
    wallet, createToken,
  } = useWeb3();

  const { total } = useAlchemy();

  const [loadingUploadImg, setLoadingUploadImg] = useState(false);
  const [loadingAdd, setLoadingAdd] = useState(false);

  const callback = () => {
    setLoadingAdd(false);

    validation.resetForm();
  };

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      name: '',
      company: '',
      email: '',
      infor: '',
      walletAddress: '',
    },

    validationSchema: Yup.object({
      // name: Yup.string()
      //   .required('Mật khẩu không được bỏ trống.'),

      // description: Yup.string()
      //   .required('Mật khẩu không được bỏ trống.'),

      // address: Yup.string()
      //   .required('Mật khẩu không được bỏ trống.'),

      // blockChain: Yup.string()
      //   .required('Mật khẩu không được bỏ trống.'),

      // paymentToken: Yup.string()
      //   .required('Mật khẩu không được bỏ trống.'),

    }),

    onSubmit: (values) => {
      console.log(values);
      setLoadingAdd(true);
      createToken(values, callback);
    },
  });

  const onChangeImg = async (e) => {
    setLoadingUploadImg(true);

    const formData = new FormData();
    formData.append('file', e.target.files[0]);
    formData.append('upload_preset', 'aeindri');
    formData.append('cloud_name', 'vinhhoang');
    formData.append('public_id', Number(total.nft) + 1);

    await fetch('  https://api.cloudinary.com/v1_1/vinhhoang/image/upload', {
      method: 'post',
      body: formData,
    })
      .then((resp) => resp.json())
      .then(async (data) => {
        await validation.setValues((preState) => ({
          ...preState,
          tokenURL: data.url,
        }));

        console.log(data.url);

        setLoadingUploadImg(false);
      })
      .catch((err) => toast.error(err.message));
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    validation.handleSubmit();
  };

  return (
    <div className="add-collection">
      <div className="add-collection__wrapper">
        {/* <div className="add-collection__wrapper--form-control">
          <div className="title">
            Preview image
            <code>*</code>
          </div>

          <div className="sub-title">
            This image will be shown at preview
          </div>

          <input type="file" id="file" />

          <label htmlFor="file" className="input-file">
            <i className="fa-solid fa-camera" />
          </label>
        </div> */}

        <div className="add-collection__wrapper--form-control">
          <div className="title">
            Name
            <code>*</code>
          </div>

          <input
            type="text"
            name="name"
            className="input-text"
            placeholder="Example: HumuTech"
            onBlur={validation.handleBlur}
            onChange={validation.handleChange}
            value={validation.values.name}
          />
        </div>

        <div className="add-collection__wrapper--form-control">
          <div className="title">
            Company
            <code>*</code>
          </div>

          <input
            type="text"
            name="company"
            className="input-text"
            placeholder="Example: HumuTech"
            onBlur={validation.handleBlur}
            onChange={validation.handleChange}
            value={validation.values.company}
          />
        </div>

        <div className="add-collection__wrapper--form-control">
          <div className="title">
            Description
            <code>*</code>
          </div>

          <div className="sub-title">
            Describe your brand values e.g. sustainability, carbon negativity, ESG etc
          </div>

          <textarea id="w3review" name="w3review" rows="10" className="input-text-area" />
        </div>

        <div className="add-collection__wrapper--form-control">
          <div className="title">
            Logo
            <code>*</code>
          </div>

          <div className="sub-title">
            This image will be used for navigation. Jpg or png recommended
          </div>

          <input type="file" id="file" onChange={onChangeImg} />

          {loadingUploadImg ? <span className="input-file-loading"><i className="fa-solid fa-spinner loading" /></span> : (
            <label htmlFor="file" className="input-file">
              {validation.values.tokenURL ? <img src={validation.values.tokenURL} alt="logo" /> : (
                <div className="smile">
                  Logo
                  <img src={smile} alt="smile" />
                </div>
              )}
            </label>
          )}
        </div>

        <div className="add-collection__wrapper--form-control">
          <div className="title">
            Email Address
            <code>*</code>
          </div>

          <input
            type="text"
            name="email"
            className="input-text"
            placeholder="Example: HumuTech"
            onBlur={validation.handleBlur}
            onChange={validation.handleChange}
            value={validation.values.email}
          />
        </div>

        <div className="add-collection__wrapper--form-control">
          <div className="title">
            Address And Contact Infor
            <code>*</code>
          </div>

          <input
            type="text"
            name="infor"
            className="input-text"
            placeholder="Example: HumuTech"
            onBlur={validation.handleBlur}
            onChange={validation.handleChange}
            value={validation.values.infor}
          />
        </div>

        <div className="add-collection__wrapper--form-control">
          <div className="title">
            Wallet Address
            <code>*</code>
          </div>

          <input
            type="text"
            name="walletAddress"
            className="input-text"
            placeholder="Example: HumuTech"
            onBlur={validation.handleBlur}
            onChange={validation.handleChange}
            value={validation.values.walletAddress}
          />
        </div>

        <button
          type="submit"
          className="btn-submit"
          onClick={handleSubmit}
          disabled={loadingAdd || loadingUploadImg}
        >
          {loadingAdd || loadingUploadImg ? <i className="fa-solid fa-spinner loading" /> : 'CREATE'}
        </button>

      </div>
    </div>
  );
}

AddCollection.propTypes = {

};

export default AddCollection;
