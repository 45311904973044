/* eslint-disable no-unused-vars */
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

import './MyCollection.scss';
import CardNFT from 'components/CardNFT';
import useWeb3 from 'hooks/useWeb3';
import useAlchemy from 'hooks/useAlchemy';

function MyCollection(props) {
  const { wallet } = useWeb3();

  const { myNFTs, fetchMyNFTs } = useAlchemy();

  useEffect(() => {
    fetchMyNFTs();
  }, [wallet.address]);

  return (
    <div className="nft">
      <div className="nft--item">
        {myNFTs && myNFTs.map((item) => <CardNFT key={item.tokenId} item={item} isMyNFT />)}
      </div>
    </div>
  );
}

MyCollection.propTypes = {

};

export default MyCollection;
