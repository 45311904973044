/* eslint-disable react/jsx-no-constructed-context-values */
/* eslint-disable no-unused-vars */
/* eslint-disable prefer-destructuring */
/* eslint-disable import/prefer-default-export */
import React, {
  createContext, useContext, useEffect, useState,
} from 'react';
import { toast } from 'react-toastify';
import PropTypes from 'prop-types';
import Web3 from 'web3';

import { ABI } from 'constants/ABI';
import { SC_ADDRESS } from 'constants/index';

const initial = {
  address: '',
  balance: '',
};

const Web3Context = createContext({
  wallet: {},
  connect: async () => {},
  createBrand: async () => {},
  createToken: async () => {},
  getIdToBrand: async () => {},
  purchaseNFT: async () => {},
});

export function Web3Provider({ children }) {
  const [wallet, setWallet] = useState(initial);

  const web3 = new Web3(window.ethereum);
  const nftContract = new web3.eth.Contract(ABI, SC_ADDRESS);

  const connect = async () => {
    try {
      const selectAccounts = await window.ethereum.request({ method: 'eth_requestAccounts' });

      setWallet((preState) => ({
        ...preState,
        address: selectAccounts[0],
      }));

      window.ethereum.on('accountsChanged', async (accounts) => {
        setWallet((preState) => ({
          ...preState,
          address: accounts[0],
        }));
      });
    } catch (error) {
      toast.error(error);
    }
  };

  const createBrand = async (data, callback) => {
    try {
      await nftContract
        .methods
        .createBrand(data.address, data.logo, data.name, data.description)
        .send({ from: wallet.address });

      toast.success('Brand has been created');

      callback();
    } catch (error) {
      toast.error(error);
    }
  };

  const createToken = async (data, callback) => {
    try {
      const res = await nftContract
        .methods
        .createToken(data.tokenURL, data.name, data.price, data.brandId)
        .send({ from: wallet.address, value: web3.utils.toWei('0.00025', 'ether') });

      toast.success('NFT has been minted');
      callback();
    } catch (error) {
      toast.error(error);
    }
  };

  const getIdToBrand = async (id, callback) => {
    try {
      const res = await nftContract
        .methods
        .idToBrand(id)
        .call();

      callback(res);
    } catch (error) {
      toast.error(error);
    }
  };

  const purchaseNFT = async (data) => {
    try {
      const res = await nftContract
        .methods
        .purchaseNFT(Number(data.tokenId))
        .send({ from: wallet.address, value: data.price });

      toast.success('Successfully purchase NFT');
    } catch (error) {
      toast.error(error);
    }
  };

  useEffect(() => {
    if (typeof window.ethereum !== 'undefined') {
      console.log('e');
    } else {
      toast.error('Please install MetaMask', { toastId: 'install' });
    }
  }, []);

  useEffect(() => {
    const getBalance = async () => {

    };

    if (wallet.address) {
      getBalance();
    }
  }, [wallet.address]);

  return (
    <Web3Context.Provider
      value={{
        wallet,
        connect,
        createBrand,
        createToken,
        getIdToBrand,
        purchaseNFT,
      }}
    >
      { children }
    </Web3Context.Provider>
  );
}

Web3Provider.propTypes = {
  children: PropTypes.node.isRequired,
};

const useWeb3 = () => useContext(Web3Context);

export default useWeb3;
