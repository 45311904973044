import React from 'react';

import Brand from 'components/Brand/Brand';

import styles from './AdminPage.module.scss';

const data = [
  {
    image: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRDtd0soCSRdpo8Y5klekJdABh4emG2P29jwg&usqp=CAU',
    name: 'Valdus',
    description: 'Example: HumuTech',
    url: 'Example: HumuTech',
    isCompleted: false,
  },
  {
    image: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSB7RWP-ntuHhdxDONcT9QCkUuQd9bXLgoYSQ&usqp=CAU',
    name: 'Uriel',
    description: 'Example: HumuTech',
    url: 'Example: HumuTech',
    isCompleted: false,
  },
  {
    image: 'https://img.freepik.com/premium-vector/female-user-profile-avatar-is-woman-character-screen-saver-with-emotions_505620-617.jpg',
    name: 'Nathaniel',
    description: 'Example: HumuTech',
    url: 'Example: HumuTech',
    isCompleted: false,
  },
];

function AdminPage() {
  return (
    <div className={styles.Container}>
      <div className={styles.Header}>
        <span className={styles.Line} />

        Recent Brands
      </div>

      {data.map((brand) => (
        <Brand
          key={brand.name}
          image={brand.image}
          name={brand.name}
          description={brand.description}
          url={brand.url}
          isCompleted={brand.isCompleted}
        />
      ))}
    </div>
  );
}
export default AdminPage;
