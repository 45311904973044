/* eslint-disable no-useless-escape */
/* eslint-disable import/prefer-default-export */
export const LOCATION = {
  HOME_PAGE: '/',
  ABOUT_PAGE: '/about',
  STORIES_PAGE: '/stories',
  ESG_PAGE: '/esg',
  LOGIN: '/login',
  ALL_NFT: '/all-nfts',
  CREATE_PAGE: '/create-page',
  ADD_COLLECTION: '/add-collection',
  My_COLLECTION: '/my-collection',
  ADMIN_PAGE: '/admin',
  USER_PAGE: '/user',
};

export const SC_ADDRESS = '0x6f8cB870dFad5E6CF3bA3977a81eCC9dB97495D9';

export const ADMIN_ADDRESS = '0x297d852f03e16c287a9ea56cd8200942c11389bf';

export const EMAIL_REGEX = /^(([^<>()\[\]\\.,;:+\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export const TEXT_LENGTH_LIMIT = {
  VERY_SHORT: 25,
  SHORT: 150,
  MEDIUM: 300,
  LONG: 2500,
  PASSWORD: 6,
};
