import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';

import styles from './Brand.module.scss';

function Brand(props) {
  const {
    image, name, description, url, isCompleted,
  } = props;

  const [isMobile, setIsMobile] = useState(false);

  const handleResize = useCallback(() => {
    setIsMobile(window.innerWidth < 600);
  }, [window.innerWidth]);

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    handleResize();

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return (
    <div className={styles.Container}>
      {isMobile ? (
        <div className={styles.InfoContainer}>
          <img src={image} alt={name} className={styles.Image} />

          <div>
            <div className={styles.Name}>
              {name}
            </div>

            <div className={styles.Description}>
              {description}
            </div>

            <div className={styles.Url}>
              {url}
            </div>
          </div>
        </div>
      ) : (
        <>
          <img src={image} alt={name} className={styles.Image} />

          <div>
            <div className={styles.Name}>
              {name}
            </div>

            <div className={styles.Description}>
              {description}
            </div>
          </div>

          <div className={styles.Url}>
            {url}
          </div>
        </>
      )}

      {isCompleted
        ? <div className={styles.Status}>Completed</div>
        : (
          <div className={styles.ButtonContainer}>
            <button type="button" className={styles.Accept}>
              <span className={styles.ButtonText}>Accept</span>
            </button>

            <button type="button" className={styles.Decline}>
              <span className={styles.ButtonText}>Decline</span>
            </button>
          </div>
        )}

      <span className={styles.Close}>
        &times;
      </span>
    </div>
  );
}

Brand.propTypes = {
  image: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
  isCompleted: PropTypes.bool.isRequired,
};

export default Brand;
