/* eslint-disable no-unused-vars */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select';
import * as Yup from 'yup';

import OptionSelect from 'components/SelectCustom/OptionSelect';
import SingleValueSelect from 'components/SelectCustom/SingleValueSelect';

import './Create.scss';
import { useFormik } from 'formik';
import useWeb3 from 'hooks/useWeb3';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';

const WALLET_LIST = [
  {
    value: 1,
    label: 'Polygon',
    img: 'https://static.wixstatic.com/media/db421e_8fd1cea14656440ba5b1b3b7498dcd2e~mv2.png/v1/fill/w_27,h_24,al_c,q_85,usm_0.66_1.00_0.01,enc_auto/polygon-matic-logo-86F4D6D773-seeklogo_com.png',
  },
];

const TOKEN_LIST = [
  {
    value: 1,
    label: 'MATIC',
    img: 'https://static.wixstatic.com/media/db421e_8fd1cea14656440ba5b1b3b7498dcd2e~mv2.png/v1/fill/w_27,h_24,al_c,q_85,usm_0.66_1.00_0.01,enc_auto/polygon-matic-logo-86F4D6D773-seeklogo_com.png',
  },
];

const customStyles = {
  control: (provided) => ({
    ...provided,
    border: '2px dashed #000',
    boxShadow: 'none',
    ':focus': {
      outline: 'none',
    },
    ':active': {
      outline: 'none',
    },
    ':hover': {
      borderColor: '#000',
      outline: 'none !important',
      cursor: 'pointer',
    },
  }),
  container: (provided) => ({
    ...provided,
    width: '163px',
    height: '36px',
  }),
};

function CreatePage(props) {
  const { createBrand } = useWeb3();
  const history = useHistory();

  const callback = () => {
    history.push('all-nfts');
  };

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      name: '',
      description: '',
      address: '',
      logo: '',
      // blockChain: '',
      // paymentToken: '',
    },

    validationSchema: Yup.object({
      // name: Yup.string()
      //   .required('Mật khẩu không được bỏ trống.'),

      // description: Yup.string()
      //   .required('Mật khẩu không được bỏ trống.'),

      // address: Yup.string()
      //   .required('Mật khẩu không được bỏ trống.'),

      // blockChain: Yup.string()
      //   .required('Mật khẩu không được bỏ trống.'),

      // paymentToken: Yup.string()
      //   .required('Mật khẩu không được bỏ trống.'),

    }),
    onSubmit: (values) => {
      console.log(values);
      createBrand(values, callback);
    },
  });

  // const handleChangeBlockChain = (value) => {
  //   console.log(value);
  //   validation.setValues((preState) => ({
  //     ...preState,
  //     blockChain: value,
  //   }));
  // };

  // const handleChangePaymentToken = (value) => {
  //   console.log(value);
  //   validation.setValues((preState) => ({
  //     ...preState,
  //     paymentToken: value,
  //   }));
  // };

  const [loadingUploadImg, setLoadingUploadImg] = useState(false);

  const onChangeImg = async (e) => {
    setLoadingUploadImg(true);

    const formData = new FormData();
    formData.append('file', e.target.files[0]);
    formData.append('upload_preset', 'aeindri');
    formData.append('cloud_name', 'vinhhoang');

    await fetch('  https://api.cloudinary.com/v1_1/vinhhoang/image/upload', {
      method: 'post',
      body: formData,
    })
      .then((resp) => resp.json())
      .then(async (data) => {
        await validation.setValues((preState) => ({
          ...preState,
          logo: data.url,
        }));

        setLoadingUploadImg(false);
      })
      .catch((err) => toast.error(err.message));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    validation.handleSubmit();
  };

  return (
    <div className="create-page">
      <div className="create-page__wrapper">
        <div className="create-page__wrapper--form-control">
          <div className="title">
            Add Logo
            <code>*</code>
          </div>

          <div className="sub-title">
            This image will be used for navigation. jpg or png recommended
          </div>

          <input type="file" id="file" onChange={onChangeImg} />

          {loadingUploadImg
            ? <span className="input-file-loading"><i className="fa-solid fa-spinner loading" /></span>
            : (
              <label htmlFor="file" className="input-file">
                {validation.values.logo
                  ? <img src={validation.values.logo} alt="logo" />
                  : (
                    <>
                      <span>Logo</span>
                      <i className="fa-regular fa-face-smile" />
                    </>
                  )}
              </label>
            )}
        </div>

        <div className="create-page__wrapper--form-control">
          <div className="title">
            Name
            <code>*</code>
          </div>

          <input
            type="text"
            name="name"
            className="input-text"
            placeholder="Example: HumuTech"
            value={validation.values.name}
            onBlur={validation.handleBlur}
            onChange={validation.handleChange}
          />
        </div>

        <div className="create-page__wrapper--form-control">
          <div className="title">
            Address
            <code>*</code>
          </div>

          <input
            type="text"
            name="address"
            className="input-text"
            placeholder="Example: HumuTech"
            value={validation.values.address}
            onBlur={validation.handleBlur}
            onChange={validation.handleChange}
          />
        </div>

        <div className="create-page__wrapper--form-control">
          <div className="title">
            Description
            <code>*</code>
          </div>

          <div className="sub-title">
            Describe your brand values e.g. sustainability, carbon negativity, ESG etc
          </div>

          <textarea
            id="description"
            name="description"
            rows="10"
            className="input-text-area"
            value={validation.values.description}
            onBlur={validation.handleBlur}
            onChange={validation.handleChange}
          />
        </div>

        <div className="create-page__wrapper--form-control">
          <div className="title">
            Blockchain
            <code>*</code>
          </div>

          <div className="sub-title mb-20">
            In this blockchain your new items will be added by default
          </div>

          <Select
            name="blockChain"
            defaultValue={WALLET_LIST[0]}
            styles={customStyles}
            options={WALLET_LIST}
            components={{
              Option: OptionSelect,
              SingleValue: SingleValueSelect,
            }}
            // onChange={handleChangeBlockChain}
          />
        </div>

        <div className="create-page__wrapper--form-control">
          <div className="title">
            Payment tokens
            <code>*</code>
          </div>

          <div className="sub-title mb-20">
            This token will be used to buy and sell your NFTs
          </div>

          <Select
            name="paymentToken"
            defaultValue={TOKEN_LIST[0]}
            styles={customStyles}
            options={TOKEN_LIST}
            components={{
              Option: OptionSelect,
              SingleValue: SingleValueSelect,
            }}
            // onChange={handleChangePaymentToken}
          />
        </div>

        <button
          type="submit"
          className="btn-submit"
          onClick={handleSubmit}
        >
          CREATE
        </button>
      </div>
    </div>
  );
}

CreatePage.propTypes = {

};

export default CreatePage;
