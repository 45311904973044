import React from 'react';

import Brand from 'components/Brand/Brand';

import styles from './UserPage.module.scss';

const data = [
  {
    image: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRDtd0soCSRdpo8Y5klekJdABh4emG2P29jwg&usqp=CAU',
    name: 'Valdus',
    description: 'Example: HumuTech',
    url: 'Example: HumuTech',
    isCompleted: true,
  },
];

function UserPage() {
  return (
    <div className={styles.Container}>
      {data.map((brand) => (
        <Brand
          key={brand.name}
          image={brand.image}
          name={brand.name}
          description={brand.description}
          url={brand.url}
          isCompleted={brand.isCompleted}
        />
      ))}
    </div>
  );
}

export default UserPage;
